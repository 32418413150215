/* eslint-disable camelcase, no-undef, no-confusing-arrow, prefer-destructuring, max-statements */
document.addEventListener('DOMContentLoaded', function popupListener() {
  const dialogText = document.getElementsByClassName('js-dialog-text')[0];
  const dialogElement = document.getElementsByClassName('dialog')[0];
  const errorDialogElement = document.getElementsByClassName('error-dialog')[0];
  const dialog = new A11yDialog(dialogElement);
  const errorDialog = new A11yDialog(errorDialogElement);
  const confirmButton = document.getElementById('js-confirm-button');
  const actionButton = document.querySelector('main');
  var contentObjectId = null;
  var method = null;
  var api = null;

  actionButton.addEventListener('click', e => {
    if (e.target.nodeName === 'A') {
      onActionClick(e);
    }
  });

  confirmButton.addEventListener('click', e => onConfirmClick(e));

  function onActionClick(mouseEvent) {
    var content = mouseEvent.target.dataset.content;
    var type = mouseEvent.target.dataset.type;
    var dataId = mouseEvent.target.dataset.id;

    method = type === 'delete' ? 'DELETE' : 'PUT';
    api = `${content}s`;
    contentObjectId = dataId;

    dialogText.innerHTML = `Are you sure you want to ${type} this content?`;
  }

  function onConfirmClick() {
    const xhr = new XMLHttpRequest();

    xhr.open(method, `/api/${api}/${contentObjectId}/`);
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.setRequestHeader('X-CSRFToken', document.cookie.split('csrftoken=')[1]);
    xhr.onload = () => (xhr.status < 200 || xhr.status > 299 ? showError() : redirect());

    xhr.onerror = () => showError();
    xhr.send(JSON.stringify({ is_approved: true }));
  }

  function redirect() {
    if (method === 'DELETE' && api === 'posts') {
      location.replace('/');
    } else {
      location.reload();
    }
  }

  function showError() {
    dialog.hide();
    errorDialog.show();
  }
});
